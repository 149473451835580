import { ReactNode } from 'react'

import Block from '@/layouts/block'
import { H1, H2, Bold } from '@/components/typography'

const HeadingLarge = ({
  title,
  isBold = false,
}: {
  title: string
  isBold?: boolean
}) =>
  isBold ? (
    <H1>
      <Bold>{title}</Bold>
    </H1>
  ) : (
    <H1>{title}</H1>
  )

const HeadingSmall = ({
  title,
  isBold = false,
}: {
  title: string
  isBold?: boolean
}) =>
  isBold ? (
    <H2>
      <Bold>{title}</Bold>
    </H2>
  ) : (
    <H2>{title}</H2>
  )

const HeadingWithIcon = ({
  className = '',
  title,
  iconComponent,
  isLarge = false,
  isBold = false,
}: {
  title: string
  iconComponent?: ReactNode
  className?: string
  isLarge?: boolean
  isBold?: boolean
}) => (
  <Block className={`w-full flex items-center gap-4 ${className}`}>
    {iconComponent && iconComponent}
    {isLarge ? (
      <HeadingLarge title={title} isBold={isBold} />
    ) : (
      <HeadingSmall title={title} isBold={isBold} />
    )}
  </Block>
)

export default HeadingWithIcon
