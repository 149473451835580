import Block from '@/layouts/block'
import Section from '@/layouts/section'
import { BooksIcon, UserIcon } from '@/components/icons'
import HeadingWithIcon from '@/components/heading'
import { HomePageRay } from '@/interfaces/schema'
import CTAStandardRayCard from '@/components/cards/cta/standard/ray'
import { routes } from '@/lib/constants'

type ProfileSectionProps = Pick<
  HomePageRay,
  | 'profileCardImage'
  | 'profileCardTitle'
  | 'profileCardDescription'
  | 'profileCardButtonText'
  | 'profileCardButtonLink'
  | 'learningCardImage'
  | 'learningCardTitle'
  | 'learningCardDescription'
  | 'learningCardButtonText'
  | 'learningCardButtonLink'
>

export const ProfileSection = ({
  profileCardImage,
  profileCardTitle,
  profileCardDescription,
  profileCardButtonText,
  profileCardButtonLink,
  learningCardImage,
  learningCardTitle,
  learningCardDescription,
  learningCardButtonText,
  learningCardButtonLink,
}: ProfileSectionProps) => (
  <Section className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-12 gap-lg place-items-center pt-9 mb-[30px] lg:mb-[120px]">
    <HeadingWithIcon
      title="Profiel"
      iconComponent={<UserIcon width="32" height="30" />}
      className={`col-span-full`}
    />
    <CTAStandardRayCard
      className="col-span-full h-full md:col-span-3 lg:col-span-6"
      image={profileCardImage}
      title={profileCardTitle}
      description={profileCardDescription}
      buttonText={profileCardButtonText}
      buttonLink={
        profileCardButtonLink
          ? profileCardButtonLink
          : routes.ray.my_profile.profile
      }
    />
    <CTAStandardRayCard
      className="col-span-full h-full md:col-span-3 lg:col-span-6"
      image={learningCardImage}
      title={learningCardTitle}
      description={learningCardDescription}
      buttonText={learningCardButtonText}
      buttonLink={
        learningCardButtonLink
          ? learningCardButtonLink
          : routes.ray.my_profile.profile
      }
    />
  </Section>
)
