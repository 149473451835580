import Section from '@/layouts/section'
import Block from '@/layouts/block'
import Link from '@/components/link'
import Image from '@/components/image'
import HeadingWithIcon from '@/components/heading'
import { P1 } from '@/components/typography'
import { ArrowRightIconSmall, BriefcaseIcon } from '@/components/icons'
import { HomePageRay } from '@/interfaces/schema'
import { useExtraSmallScreen } from '@/hooks/useMediaQuery'
import { routes } from '@/lib/constants'

export const VacanciesSection = ({
  vacanciesCardImage,
  vacanciesCardTitle,
  vacanciesCardButtonText,
}: Pick<
  HomePageRay,
  'vacanciesCardImage' | 'vacanciesCardTitle' | 'vacanciesCardButtonText'
>) => {
  const isExtraSmallScreen = useExtraSmallScreen()

  return (
    <Section className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-12 gap-lg place-items-center mb-[30px] lg:mb-[120px]">
      <HeadingWithIcon
        title="Vacatures"
        iconComponent={<BriefcaseIcon width="36" height="30" />}
        className="col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-start-1 lg:col-end-13"
      />
      <div className="flex flex-col md:flex-row justify-center md:justify-start gap-4 md:gap-16 row-start-2 col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-start-1 lg:col-end-13 w-full min-h-[265px] bg-neutral-white rounded-sm p-11">
        <Block className="flex items-center justify-center min-w-[25%] md:justify-start xl:min-w-[15%]">
          <Image
            src={vacanciesCardImage?.url ?? ''}
            alt="bag"
            width={`${isExtraSmallScreen ? '55px' : '155px'}`}
            height={`${isExtraSmallScreen ? '52px' : '149px'}`}
          />
        </Block>
        <Block className="flex flex-col gap-6 justify-center items-center md:items-start">
          <h3 className="Q1 Q1--ray max-w-[20ch]">{vacanciesCardTitle}</h3>
          <Link
            href={routes.ray.vacancies}
            passHref
            className="inline-flex items-center gap-2"
          >
            <P1>{vacanciesCardButtonText}</P1>
            <ArrowRightIconSmall />
          </Link>
        </Block>
      </div>
    </Section>
  )
}
