import { GetStaticPropsContext } from 'next'
import { useState } from 'react'

import withAuth from '@/lib/auth/with-auth'
import PageProps from '@/interfaces/page'
import { HomePageRay } from '@/interfaces/schema'
import Main from '@/layouts/main'
import {
  Banner,
  VacanciesSection,
  ProfileSection,
} from '@/sections/ray/dashboard'
import { StoriesSection } from '@/sections/repeatable/stories'
import { FaqSection } from '@/sections/repeatable/faq'
import { getHomePageRayData } from '@/services/contentful/api'
import { getAuth } from 'firebase/auth'
import { getFireStoreUser } from '@/services/firebase/client/utils/user'

const DashboardPage = ({ data }: PageProps<HomePageRay>) => {
  const {
    bannerImage,
    bannerCardButtonText,
    displayVacanciesSection,
    vacanciesCardImage,
    vacanciesCardTitle,
    vacanciesCardButtonText,
    displayProfileSection,
    profileCardImage,
    profileCardTitle,
    profileCardDescription,
    profileCardButtonText,
    profileCardButtonLink,
    learningCardImage,
    learningCardTitle,
    learningCardDescription,
    learningCardButtonText,
    learningCardButtonLink,
    displayStoriesSection,
    storiesSectionIcon,
    storiesSectionTitle,
    storiesCollection,
    storiesButtonText,
    displayFaqSection,
    faqIcon,
    faqTitle,
    faqSubtitle,
    faqsCollection,
  } = data

  const [welcomeText, setWelcomeText] = useState(bannerCardButtonText)

  const { currentUser } = getAuth()

  if (currentUser == null) return
  getFireStoreUser(currentUser.email ?? '').then((user) => {
    if (user == null) return
    setWelcomeText('Welkom ' + user.firstName + ', bij Ray!')
  })

  return (
    <Main>
      <Banner bannerImage={bannerImage} bannerCardButtonText={welcomeText} />
      {displayVacanciesSection && (
        <VacanciesSection
          vacanciesCardImage={vacanciesCardImage}
          vacanciesCardTitle={vacanciesCardTitle}
          vacanciesCardButtonText={vacanciesCardButtonText}
        />
      )}
      {displayProfileSection && (
        <ProfileSection
          profileCardImage={profileCardImage}
          profileCardTitle={profileCardTitle}
          profileCardDescription={profileCardDescription}
          profileCardButtonText={profileCardButtonText}
          profileCardButtonLink={profileCardButtonLink}
          learningCardImage={learningCardImage}
          learningCardTitle={learningCardTitle}
          learningCardDescription={learningCardDescription}
          learningCardButtonText={learningCardButtonText}
          learningCardButtonLink={learningCardButtonLink}
        />
      )}
      {displayStoriesSection && (
        <StoriesSection
          storiesSectionIcon={storiesSectionIcon}
          storiesSectionTitle={storiesSectionTitle}
          storiesCollection={storiesCollection}
          storiesButtonText={storiesButtonText}
        />
      )}
      {displayFaqSection && (
        <FaqSection
          faqIcon={faqIcon}
          faqTitle={faqTitle}
          faqSubtitle={faqSubtitle}
          faqsCollection={faqsCollection}
        />
      )}
    </Main>
  )
}

export async function getStaticProps({ preview }: GetStaticPropsContext) {
  const isPreview: boolean = !!preview ?? false
  const { data: pageData, error: pageError } = await getHomePageRayData(
    isPreview
  )

  return {
    props: {
      data: { navData: null, pageData },
      error: { navError: null, pageError },
    },
    revalidate: 60,
  }
}

// TODO fix typing
export default withAuth<HomePageRay>(DashboardPage as any)
