import {
  doc,
  DocumentData,
  QueryDocumentSnapshot,
  setDoc,
  updateDoc,
  Timestamp,
  getDocFromServer,
  query,
} from '@firebase/firestore'

import { Firestore, FirestoreUser } from '@/interfaces/firebase'
import { dbClient } from '@/services/firebase/client/config'

const converter = {
  toFirestore: (data: FirestoreUser) => data,
  fromFirestore: (snap: QueryDocumentSnapshot<DocumentData>) =>
    snap.data() as FirestoreUser,
}

export async function getFireStoreUser(email: string) {
  const docRef = doc(dbClient, Firestore.User, email).withConverter(converter)
  try {
    const docSnap = await getDocFromServer(docRef)

    if (!docSnap.exists) throw new Error('User does not exist')
    const user: FirestoreUser | undefined = docSnap.data()

    return user
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function createFireStoreUser({
  candidateId,
  uid,
  firstName,
  lastName,
  infix = '',
  email,
  phoneNumber,
  emailVerified,
  ownerId,
  backupCids,
}: FirestoreUser) {
  if (!candidateId || !email)
    throw new Error('Candidate ID and email are required')

  const docRef = doc(dbClient, Firestore.User, email)
  const payload: any = {
    candidateId,
    uid,
    firstName,
    lastName,
    infix,
    email,
    phoneNumber,
    emailVerified,
    ownerId,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  }

  if (backupCids != null) {
    payload.backupCids = backupCids
  }

  try {
    const newDoc = await setDoc(docRef, payload)

    return newDoc
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function updateFireStoreUser(
  email: string,
  fields: Partial<FirestoreUser>
) {
  if (!email) throw new Error('Candidate ID and email are required')

  const docRef = doc(dbClient, Firestore.User, email)
  const updatedDoc = await updateDoc(docRef, {
    ...fields,
    updatedAt: Timestamp.fromDate(new Date()),
  })

  return updatedDoc
}
