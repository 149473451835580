import { useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import LocalStorageData from '@/interfaces/local-storage'
import { Candidate } from '@/interfaces/candidate'
import useFetch from './useFetch'
import initLocalStorageData from '@/lib/factory/local-storage'
import { API_ENDPOINTS } from '@/services/serverless/api/config'

const useUserName = () => {
  const [localStorageData, setLocalStorageData] =
    useLocalStorage<LocalStorageData>('rvdb', initLocalStorageData)
  const [isErrorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const handleCloseErrorSnackbar = () => setErrorSnackbarOpen(false)

  const triggerFetch =
    localStorageData.candidateId && !localStorageData.firstName

  useFetch<Candidate>({
    url: triggerFetch ? API_ENDPOINTS.CANDIDATE.GET : null,
    options: {
      onSuccess(data) {
        const userName = data.person.firstName

        setLocalStorageData((prevState: LocalStorageData) => {
          return {
            ...prevState,
            userName,
          }
        })
      },
      onError(error) {
        setErrorMessage(error.message)
        return setErrorSnackbarOpen(true)
      },
    },
  })

  return {
    userName: localStorageData.firstName,
    errorMessage,
    isErrorSnackbarOpen,
    handleCloseErrorSnackbar,
  }
}

export default useUserName
