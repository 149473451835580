import { Dispatch, SetStateAction } from 'react'
import { User, UserCredential } from 'firebase/auth'
import { InferType } from 'yup'
import {
  changePasswordValidationSchema,
  emailFormValidationSchema,
  loginFormValidationSchema,
  setupPasswordValidationSchema,
} from '@/lib/form-validation'
import { BlobFilesProps } from './files'
import { AxiosError, AxiosResponse } from 'axios'

export type LoginFormValuesTypes = InferType<typeof loginFormValidationSchema>

export type SignupFormValuesTypes = {
  email: string
  password: string
  confirmPassword: string
  firstName: string
  infix: string
  lastName: string
  phoneNumber: string
  verificationCode: string
  privacyAgreement: boolean
  extraInfo7: string
  files: BlobFilesProps
}

export type RecoverPasswordFormValuesTypes = InferType<
  typeof emailFormValidationSchema
>

export type ChangePasswordForm = InferType<
  typeof changePasswordValidationSchema
>

export type SetupPasswordFormValuesTypes = InferType<
  typeof setupPasswordValidationSchema
>

export type FirebaseUser = Pick<
  User,
  'uid' | 'email' | 'displayName' | 'emailVerified'
>

export enum Firestore {
  Session = 'session',
  User = 'user',
  Migration = 'migration',
}

export type FirestoreUser = {
  candidateId: string
  uid?: string
  firstName: string
  lastName: string
  infix: string
  email: string
  phoneNumber: string
  ownerId: string
  emailVerified: boolean
  backupCids?: string[]
  createdAt?: Date
  updatedAt?: Date
}

export type UserAuthState = {
  email: string
  emailVerified: boolean
}

export interface MfaUserInfo {
  displayName: string
  enrolledAt: string
  mfaEnrollmentId: string
  phoneInfo: string
}

export interface AuthUser extends User {
  reloadUserInfo: {
    mfaInfo: MfaUserInfo[]
  } | null
}

export interface AuthProps {
  user: AuthUser | null
  isAuthenticated: boolean
  setIsAuthenticated: Dispatch<SetStateAction<boolean>> | null
  recoverPassword: ((email: string) => Promise<void>) | null
  resetPassword:
    | (({
        newPassword,
        oobCode,
      }: {
        newPassword: string
        oobCode: string
      }) => Promise<void>)
    | null
  updateUser: ((user: AuthUser) => void) | null
  login:
    | ((
        email: string,
        password: string,
        phoneNumber?: string
      ) => Promise<AxiosResponse | AxiosError>)
    | null
  signup: ((email: string, password: string) => Promise<User>) | null
  migrate: ((email: string, password: string) => Promise<void>) | null
  logout: (() => Promise<void>) | (() => void)
  loginWithCustomToken: ((token: string) => Promise<boolean>) | null
  changePassword: ((newPassword: string) => Promise<void>) | (() => void)
  verifyEmail: ((actionCode: string) => Promise<void>) | null
}
