import Section from '@/layouts/section'
import Block from '@/layouts/block'
import { H1, H2 } from '@/components/typography'
import { ButtonLinkPrimaryArrow } from '@/components/button'
import useUserName from '@/hooks/useUserName'
import { HomePageRay } from '@/interfaces/schema'
import getBgImgStyles from '@/lib/bg-image'
import { useExtraSmallScreen, useMediumScreen } from '@/hooks/useMediaQuery'
import { GRID, routes } from '@/lib/constants'
import Snackbar from '@/components/snackbar'

export const Banner = ({
  bannerImage,
  bannerCardButtonText,
}: Pick<HomePageRay, 'bannerImage' | 'bannerCardButtonText'>) => {
  const {
    userName,
    isErrorSnackbarOpen,
    errorMessage,
    handleCloseErrorSnackbar,
  } = useUserName()
  const isExtraSmallScreen = useExtraSmallScreen()
  const isMediumScreen = useMediumScreen()
  const imgHeight = isExtraSmallScreen ? 215 : 618
  const imgWidth = isMediumScreen ? 'calc(100% + 10px)' : 'calc(100% + 80px)'

  const bg = getBgImgStyles({
    url: bannerImage?.url ?? '',
    height: imgHeight,
    width: imgWidth,
    parallax: false,
  })

  return (
    <Section
      styles={bg}
      className="relative rounded-sm w-full !max-w-[1360px] mb-[60px] lg:mb-[120px]"
    >
      <Block className={`${GRID} mx-[5px] lg:mx-[40px]`}>
        <div className="flex flex-col col-span-full lg:col-start-6 lg:col-end-12 w-full mt-[170px] sm:mt-[509px] max-h-[190px] rounded-sm bg-neutral-white p-4 sm:p-10">
          {userName && <H1>Hi {userName}</H1>}
          <div className="flex justify-between">
            <H2 className="mr-4 self-end">{bannerCardButtonText}</H2>
            <ButtonLinkPrimaryArrow href={routes.ray.vacancies} />
          </div>
        </div>
      </Block>
      <Snackbar
        message={errorMessage}
        isOpen={isErrorSnackbarOpen}
        onClose={handleCloseErrorSnackbar}
      />
    </Section>
  )
}
